import { HammerGestureConfig } from '@angular/platform-browser';
import { Injectable } from "@angular/core";
@Injectable()
export class CustomHammerConfig extends HammerGestureConfig {

	override overrides: { [key: string]: Object } = {
		'pinch': { enable: false },
		'rotate': { enable: false }
	}
}
