<mat-toolbar>
  <span routerLink="/">Berufsweg</span>
</mat-toolbar>

<header class="container-fluid">

      <!-- header background video-->
      <video id="backgroundvideo" autoplay muted loop>
        <source src="assets/videos/intro.mp4"  type="video/mp4">
      </video>

      <div class="black-overlay"></div>

      <div class="row">
        <div class="col-12 col-sm-8 offset-sm-2 col-lg-6  offset-lg-3 carousel">
          <!-- slide -->
          <div class="slider" (swipeleft)="next()" (swiperight)="previous()" [@sliderState]="stateForSlider()">
            <div class="slide" *ngFor="let slide of slides; let i= index">
              <div class="col-8 offset-2 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 ps-4 pe-4">
                <p class="text-center">
                  <mat-icon class="big-icon">{{slide.icon}}</mat-icon>
                </p>
                <h1 class="text-center">{{slide.title}}</h1>
                <p class="text-center">{{slide.text}}</p>
              </div>
            </div>
          </div>

          <!-- slide-buttons and indicator-->
          <button mat-fab (click)="previous()" color="primary" class="left">
            <mat-icon>chevron_left</mat-icon>
          </button>

          <button mat-fab (click)="next()" color="primary" class="right">
            <mat-icon>chevron_right</mat-icon>
          </button>

          <div class="text-center page-indicator">
            <span *ngFor="let slide of slides; let i = index" [ngClass]="{'active': i == currentSlide}" class="indicator">&bull;</span>
          </div>
        </div>
      </div>

        <!-- error -->
        <error-message (close)="error = null" [error]="error" row="true"></error-message>

  <div class="row">

    <div class="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3">
      <div class="row gy-2">
          <!-- login button -->
          <div *ngIf="!isLoggedIn" class="col-12 col-md-6">
            <a mat-stroked-button class="col-12" routerLink="/login" [disabled]="loading">Login</a>
          </div>

          <!-- student register button -->
          <div *ngIf="!isLoggedIn && !isCompany()" class="col-12 col-md-6">
            <button mat-raised-button color="primary" class="col-12" [disabled]="loading" (click)="createUser()">
              <loading-label [loading]="loading " [idle]=" 'Los geht`s: ' + role "></loading-label>
            </button>
          </div>

          <!-- company register button -->
          <div *ngIf="!isLoggedIn && isCompany()" class="col-12 col-md-6">
            <a mat-raised-button color="primary" href="mailto:kontakt@berufsweg.de?subject=Neuer%20Unternehmensaccount&body=Hallo,%0A%0Agerne%20würden%20wir%20für%20unser%20Unternehmen%20__Unternehmensname__%20einen%20Account%20registrieren.%0A%0AUnsere Unternehmensdaten sind:%0AUnternehmensname:%0AAdresse:%0AEmail-Adresse:%0ATelefonnummer:%0AName der Kontaktperson:%0AWebseite:%0A%0AFreundliche%20Grüße%0A%0A" class="col-12" [disabled]="loading">Schicken Sie uns eine Mail</a>
          </div>

          <!-- dashboard button -->
          <div *ngIf="isLoggedIn" class="col-12 col-md-6">
            <a mat-raised-button color="primary" routerLink="/" [disabled]="loading">Zum Dashboard</a>
          </div>
      </div>
    </div>
  </div>

  <!-- scroll to content button -->
  <div class="scroll-to-container text-center">
    <button mat-mini-fab color="accent" class="scollToContentBtn" (click)="introText.scrollIntoView({behavior: 'smooth'})">
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
  </div>

  <div class="footer-wave">
    <img src="assets/images/wave.svg" alt="wave">
  </div>
  </header>

<div #introText>
  <intro-text></intro-text>
</div>
