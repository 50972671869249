import { NgModule, Optional, SkipSelf } from '@angular/core';

import { CommonModule, DatePipe } from '@angular/common';
import { ProfileService } from '../profile/profile.service';
import { LikesService } from '../social/likes/likes.service';
import { MqttService } from '../messenger/mqtt.service';
import { NotificationsService } from '../shared/notification/notifications.service';
import { MessageNotificationService } from '../messenger/message-notification.service';
import { SessionService } from '../user/session.service';
import { UniversallyUniqueIdentifier } from '../user/universally-unique-identifier';
import { TutorialService } from '../shared/tutorial-box/tutorial.service';
import { FileSaverService } from '../shared/file-saver/file-saver.service';
import { TermsService } from '../more/terms/terms.service';
import { LocationService } from '../shared/location/location.service';
import { GeolocationService } from '../search/geolocation.service';
import { SponsorService } from '../shared/sponsor/sponsor.service';
import { DocumentService } from '../course/files/document.service';
import { NoPremiumNotificationCenterService } from '../user/no-premium/no-premium-notification-center.service';
import { PremiumService } from '../user/premium.service';
import { PremiumGuardService } from '../user/premium-guard.service';
import { CompanyContactService } from '../profile/contact-persons/company-contact.service';
import { MessengerService } from '../messenger/messenger.service';
import { CompanyProfileService } from '../profile/company-profile.service';
import { FilesService } from '../course/files/files.service';
import { IsCompanyGuardService } from '../user/is-company-guard.service';
import { IsStudentGuardService } from '../user/is-student-guard.service';
import { UploaderService } from '../course/files/uploader.service';
import { ImageService } from '../shared/images/image.service';
import { NoAccountNotificationCenterService } from '../user/no-account/no-account-notification-center.service';
import { TurbotestStatusService } from '../tests/turbotest-status.service';
import { ConsultantsService } from '../consultants/consultants.service';
import { ProfilePictureService } from '../profile/profile-picture.service';
import { AccountService } from '../shared/login/account.service';
import { SchoolService } from '../profile/base-profile/school/school.service';
import { PrivacyService } from '../more/privacy/privacy.service';
import { SpeedDatingService } from '../speeddating/services/speed-dating.service';
import { QrCodeService } from '../course/tests/foreign/create-test/test-invitation/qr-code/qr-code.service';
import { SearchService } from '../shared-search/search.service';
import { IMqttServiceOptions, MqttModule } from 'ngx-mqtt';
import { environment } from '../../environments/environment';
import { MenuService } from './menu-service/menu-service';

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: environment.mqtt.host,
  port: environment.mqtt.port,
  path: '/mqtt'
};

@NgModule({
  imports: [
    CommonModule,
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS)
  ],
  declarations: [],
  providers: [
    ProfileService,
    LikesService,
    SessionService,
    UniversallyUniqueIdentifier,
    MessengerService,
    DatePipe, // because an instance is used in pure code
    MqttService,
    NotificationsService,
    MessageNotificationService,
    AccountService,
    ConsultantsService,
    ProfilePictureService,
    ImageService,
    NoAccountNotificationCenterService,
    TurbotestStatusService,
    CompanyProfileService,
    FilesService,
    IsStudentGuardService,
    IsCompanyGuardService,
    UploaderService,
    CompanyContactService,
    NoPremiumNotificationCenterService,
    PremiumService,
    PremiumGuardService,
    DocumentService,
    SponsorService,
    LocationService,
    GeolocationService,
    TermsService,
    TutorialService,
    FileSaverService,
    SchoolService,
    SearchService,
    PrivacyService,
    SpeedDatingService,
    QrCodeService,
    MenuService
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }

}
