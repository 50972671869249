/* src/app/intro/intro.component.scss */
mat-toolbar {
  z-index: 1000;
  background: rgba(0, 0, 0, 0);
  position: absolute;
  color: white;
}
header {
  padding-top: 20vh;
  min-height: 100vh;
  position: relative;
}
video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  background-image: url(/assets/images/tiles.jpg);
  object-fit: cover;
}
.black-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}
.big-icon {
  font-size: 80px;
  height: 80px;
  width: 80px;
}
.page-indicator .indicator {
  font-size: 30px;
  color: white;
  font-weight: bold;
}
.page-indicator .indicator.active {
  color: #E91E63;
}
.scroll-to-container {
  margin-top: 40px;
  padding-bottom: 80px;
}
.scroll-to-container button {
  z-index: 1;
  background-color: transparent;
  color: white;
  border-style: solid;
  border-color: white;
  font-size: 30px;
  height: 30px;
  width: 30px;
}
.footer-wave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.carousel {
  position: relative;
  overflow: hidden;
}
.carousel button {
  display: inline-block;
  position: absolute;
  top: calc(50% - 60px);
}
.carousel button.left {
  left: 16px;
}
.carousel button.right {
  right: 16px;
}
.carousel .slider {
  width: 200%;
  position: relative;
  overflow: hidden;
  display: flex;
}
.carousel .slider .slide {
  display: inline-block;
  flex-basis: 50%;
  color: white;
}
/*# sourceMappingURL=intro.component.css.map */
