<app-overlay [show]="busy">
  <h1>
    <loading-label loading="true" active=""></loading-label>
  </h1>
</app-overlay>
<router-outlet></router-outlet>
<verification-reminder-popup></verification-reminder-popup>
<no-account-popup></no-account-popup>
<no-premium-popup></no-premium-popup>
<sponsor-popup></sponsor-popup>
