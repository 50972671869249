import { Injectable } from '@angular/core';
import * as _ from "lodash-es";
declare var Notification;

@Injectable()
export class NotificationsService {

  private defaults = {
    // badge: null,
    // body: null,
    // dir: null,
    // lang: null,
    // tag: null,
    // icon: null,
  }

  constructor() { }

  public sendNotification(title: string, options: any, callback?: Function): any {
    options = _.merge(_.clone(this.defaults), options);
    if (!("Notification" in window)) {
      console.log("Notifications-Feature is not available");
      return null;
    }
    else if (Notification.permission === "granted") {
      return this.showNotification(title, options, callback);
    }
    else if (Notification.permission !== 'denied') {
      Notification.requestPermission((permission) => {
        if (permission === "granted") {
          return this.showNotification(title, options, callback);
        }
      });
    }
  }

  private showNotification(title: string, options: any, callback?: Function) {
    var notification = new Notification(title, options);
    notification.onclick = callback;
    notification.onerror = null;
    return notification;
  }
}
