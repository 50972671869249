<ng-template #loginPopup>
  <div class="container">
    <div id="header">
      <h2 class="mb-0">Kein Benutzer-Konto</h2>
      <button mat-icon-button  (click)="closeDialog()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div id="content">
      <a routerLink="/login" (click)="closeDialog()">
        <span *ngIf="isStudent(); else isCompany">
          Logge dich ein
        </span>
        <ng-template #isCompany>
          Loggen Sie sich ein
        </ng-template>
      </a>
      <span *ngIf="isStudent(); else isCompany">
        oder erstelle ein
        <a [routerLink]="'/signup/userdata' | returnRoute" (click)="closeDialog()">
          neues Benutzer-Konto
        </a>
      </span>
      <ng-template #isCompany>
        oder erstellen Sie ein
        <a [routerLink]="'/signup/userdata-company' | returnRoute" (click)="closeDialog()">
          neues Benutzer-Konto
        </a>
      </ng-template>
    </div>
  </div>
</ng-template>
