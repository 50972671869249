import { ModuleWithProviders } from '@angular/core';
import { mapToCanActivate, mapToCanMatch, PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { IntroComponent } from './intro';
import { NoTurbotestGuardService } from './tests/no-turbotest-guard.service';
import { TurbotestGuardService } from './tests/turbotest-guard.service';
import { NoLoginGuardService } from './user/no-login-guard.service';
import { PremiumGuardService } from './user/premium-guard.service';

const appRoutes: Routes = [
  {
    path: 'intro',
    component: IntroComponent,
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canMatch: mapToCanMatch([NoTurbotestGuardService])
  },
  {
    path: 'speeddating',
    loadChildren: () => import('./speeddating/speeddating.module').then(m => m.SpeeddatingModule),
    canMatch: mapToCanMatch([NoTurbotestGuardService])
  },
  {
    path: 'hall-of-fame',
    loadChildren: () => import('./hall-of-fame/hall-of-fame.module').then(m => m.HallOfFameModule),
    canMatch: mapToCanMatch([NoTurbotestGuardService])
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    canActivate: mapToCanActivate([NoLoginGuardService])
  },
  {
    path: 'register',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule), data: {
      'hideNavBar': true
    },
    canActivate: mapToCanActivate([NoLoginGuardService])
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule), data: {
      'hideNavBar': true
    }
  },
  {
    path: 'tests',
    loadChildren: () => import('./tests/test.module').then(m => m.TestModule),
  },
  {
    path: 'course', loadChildren: () => import('./course/course.module').then(m => m.CourseModule),
    canMatch: mapToCanMatch([NoTurbotestGuardService, PremiumGuardService]),
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
    canMatch: mapToCanMatch([NoTurbotestGuardService]),
  },
  {
    path: 'vacancies',
    loadChildren: () => import('./vacancies/vacancies.module').then(m => m.VacanciesModule),
  },
  {
    path: 'companies',
    loadChildren: () => import('./company/company.module').then(m => m.CompanyModule),
  },
  {
    path: 'careers',
    loadChildren: () => import('./careers/careers.module').then(m => m.CareersModule),
  },
  {
    path: 'messenger',
    loadChildren: () => import('./messenger/messenger.module').then(m => m.MessengerModule),
    canMatch: mapToCanMatch([NoTurbotestGuardService]),
  },
  {
    path: 'search',
    loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
  },
  {
    path: 'more',
    loadChildren: () => import('./more/more.module').then(m => m.MoreModule),
  },
  {
    path: 'foreign-test',
    loadChildren: () => import('./foreign-test/foreign-test.module').then(m => m.ForeignTestModule),
  },
  {
    path: 'premium',
    loadChildren: () => import('./voucher/voucher.module').then(m => m.VoucherModule),
  },
  {
    path: 'digitalpartners',
    loadChildren: () => import('./digitalpartners/digitalpartners.module').then(m => m.DigitalpartnersModule),
  },
  {
    path: 'newsletter',
    loadChildren: () => import('./newsletter/newsletter.module').then(m => m.NewsletterModule),
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
];

export const appRoutingProviders: any[] = [
  NoLoginGuardService, NoTurbotestGuardService, TurbotestGuardService
];

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(appRoutes, {
  preloadingStrategy: PreloadAllModules
});
