/* src/app/intro/intro-text/intro-text.component.scss */
.grey-row {
  background-color: #EBEBEB;
  padding-top: 32px;
}
.white-row {
  background-color: white;
  padding-top: 32px;
}
.content-container {
  margin-bottom: 60px;
}
.product-video {
  text-align: center;
}
.product-video .i-frame {
  width: 100%;
  aspect-ratio: 4/3;
}
.carousel {
  position: relative;
}
.carousel button {
  display: inline-block;
  position: absolute;
  bottom: 16px;
}
.carousel button.left {
  left: 0;
}
.carousel button.right {
  right: 0;
}
.carousel .slider {
  background-color: transparent;
  display: block;
  width: 100%;
  height: 320px;
  position: relative;
  overflow: hidden;
}
.carousel .slider .slide {
  width: 100%;
  height: 100%;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
.carousel .slider .slide .slide-content {
  height: 100%;
}
.carousel .slider .slide .text-center {
  height: 100%;
}
.carousel .slider .slide img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.start-button-container {
  text-align: center;
}
.app-buttons {
  display: flex;
  justify-content: center;
  gap: 16px;
  text-align: center;
  margin-bottom: 15px;
}
.app-buttons svg {
  fill: white;
}
footer {
  background-image: url("./media/tiles.jpg");
}
footer .links {
  margin-top: 32px;
}
footer .links a {
  text-decoration: none;
  color: #E91E63;
}
footer .links a:hover {
  text-decoration: underline;
}
footer .links h3 {
  color: white;
}
footer .copyright {
  color: gray;
}
.indicators .active {
  color: #E91E63;
}
/*# sourceMappingURL=intro-text.component.css.map */
