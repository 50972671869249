/* src/app/signup/verification-reminder/verification-reminder.component.scss */
#header {
  display: flex;
  justify-content: space-between;
}
.button-position {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
}
.reminder {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  margin-left: 2rem;
  z-index: 9998;
  color: black;
  background: white;
  padding: 20px 20px 10px;
  opacity: 95%;
  border-radius: 8px;
  border-style: solid;
  border-color: #E91E63;
}
/*# sourceMappingURL=verification-reminder.component.css.map */
