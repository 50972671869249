<div *ngIf="shouldShowReminder | async" class="reminder">
  <div id="header">
    <strong>E-Mail-Adresse bestätigen </strong>
    <button class="btn-close" (click)="closeDialog()" aria-label="Close"></button>
  </div>
  <br>
  <span>
    Wir haben eine Bestätigungs-Mail an {{email | async}} geschickt.<br>
    Bitte auf den Link in der Mail klicken und die E-Mail-Adresse bestätigen.
  </span>
  <br>
  <div class="button-position">
    <button mat-button color="primary" (click)="sendNewMail()">Neue Bestätigungs-Mail senden</button>
  </div>
</div>

