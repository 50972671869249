/* src/app/user/no-account/no-account.component.scss */
.container {
  display: block;
  background: #fff;
  border-radius: 8px;
  padding: 8px 16px 16px;
}
#header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/*# sourceMappingURL=no-account.component.css.map */
