import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';

import {NotificationsService} from '../shared/notification/notifications.service';
import {MqttService} from '../messenger/mqtt.service';
import {Message} from '../messenger/models/message';
import {delay, filter, map, retryWhen} from 'rxjs/operators';

@Injectable()
export class MessageNotificationService {

  private pattern = /\/messenger/

  constructor(private pushMessageService: MqttService, private notificationService: NotificationsService, private router: Router) {
  }

  public showMessageNotifications(): Observable<any> {
    return this.pushMessageService.onMessage.pipe(
      filter(x => !this.pattern.test(this.router.url)),
      map(message => this.sendNotification(message)))
  }

  private sendNotification(message: Message): any {
    const callback = () => this.router.navigate(['messenger', 'conversation', message.fromUserId]);
    const options = {
      body: message.message
    }
    return this.notificationService.sendNotification(message.fromUsername, options, callback);
  }

}
