import { Component, OnInit } from '@angular/core';
import {trigger, state, style, transition, animate} from '@angular/animations';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'intro-text',
  templateUrl: './intro-text.component.html',
  styleUrls: ['./intro-text.component.scss'],
  animations: [
    trigger('sliderState', [
      state('previous', style({
        transform: 'translateX(-100%)'
      })),
      state('active', style({
        transform: 'translateX(0)'
      })),
      state('next', style({
        transform: 'translateX(+100%)'
      })),
      transition('previous => active', animate('300ms ease-in-out')),
      transition('active => previous', animate('300ms ease-in-out')),
      transition('next => active', animate('300ms ease-in-out')),
      transition('active => next', animate('300ms ease-in-out')),
      transition('next => previous', animate('0ms ease-in-out')),
      transition('previous => next', animate('0ms ease-in-out')),
    ])
  ]
})
export class IntroTextComponent implements OnInit {
  loading: boolean = false;
  error: any = null;
  currentAppSlide: number = 0;
  currentWebSlide: number = 0;
  baseUrl: string = environment.baseUrl
  staticHtmlUrl: string = environment.baseUrl + environment.staticHtmlPrefix

  webSlides = [
    {
      img: "assets/images/web-slides/1.png"
    },
    {
      img: "assets/images/web-slides/2.png"
    },
    {
      img: "assets/images/web-slides/3.png"
    },
    {
      img: "assets/images/web-slides/4.png"
    }
  ];

  appSlides = [
    {
      img: "assets/images/app-slides/1.png"
    },
    {
      img: "assets/images/app-slides/2.png"
    },
    {
      img: "assets/images/app-slides/3.png"
    },
    {
      img: "assets/images/app-slides/4.png"
    }
  ];

  constructor() { }

  ngOnInit() {
  }

  gotoCreateUserBtn() {
    window.scrollTo(0, 0);
  }

  nextWebSlide() {
    this.currentWebSlide = (this.currentWebSlide + 1) % this.webSlides.length;
  }

  previousWebSlide() {
    this.currentWebSlide = (this.webSlides.length + this.currentWebSlide - 1) % this.webSlides.length
  }

  stateForWebSlide(index: number): string {
    if (index == this.currentWebSlide) {
      return "active";
    } else if (index == (this.webSlides.length + this.currentWebSlide - 1) % this.webSlides.length) {
      return "previous";
    } else {
      return "next";
    }
  }

  nextAppSlide() {
    this.currentAppSlide = (this.currentAppSlide + 1) % this.appSlides.length;
  }

  previousAppSlide() {
    this.currentAppSlide = (this.appSlides.length + this.currentAppSlide - 1) % this.appSlides.length
  }

  stateForAppSlide(index: number): string {
    if (index == this.currentAppSlide) {
      return "active";
    } else if (index == (this.appSlides.length + this.currentAppSlide - 1) % this.appSlides.length) {
      return "previous";
    } else {
      return "next";
    }
  }

}
