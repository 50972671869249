import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { VerificationReminderService } from './verification-reminder.service';

@Component({
  selector: 'verification-reminder-popup',
  templateUrl: './verification-reminder.component.html',
  styleUrls: ['./verification-reminder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerificationReminderComponent {

  constructor(private verificationReminderService: VerificationReminderService) {
  }

  closeDialog() {
    this.verificationReminderService.closeReminder()
  }

  sendNewMail() {
    this.verificationReminderService.sendNewMail()
  }

  get shouldShowReminder(): Observable<boolean> {
    return this.verificationReminderService.shouldShowReminder;
  }

  get email(): Observable<string> {
    return this.verificationReminderService.email;
  }
}
