import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { NoAccountNotificationCenterService } from './no-account-notification-center.service';
import { SessionService } from '../session.service';
import { UserType } from '../user-type.enum';
import {Dialog, DialogRef} from '@angular/cdk/dialog';
import {Router} from '@angular/router';

@Component({
  selector: 'no-account-popup',
  templateUrl: './no-account.component.html',
  styleUrls: ['./no-account.component.scss']
})
export class NoAccountComponent implements OnInit {

  @ViewChild('loginPopup') public loginPopup: TemplateRef<any>;
  private dialogRef: DialogRef<string>;

  constructor(private noAccountNotificationCenter: NoAccountNotificationCenterService, private sessionService: SessionService,
              public dialog: Dialog, private router: Router) { }

  ngOnInit() {
    this.noAccountNotificationCenter.accountErrors.subscribe(
      x => this.openDialog());
  }

  isStudent() {
    return this.sessionService.type === UserType.STUDENT;
  }

  openDialog() {
    if (!this.dialogRef) {
      this.dialogRef = this.dialog.open(this.loginPopup, {
        minWidth: '300px'
      });
    }
  }

  closeDialog() {
    this.dialogRef.close();
    this.dialogRef = undefined;
  }

}
