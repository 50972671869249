import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { MessageNotificationService } from './messenger/message-notification.service';
import { SessionService } from './user/session.service';
import { MqttService } from './messenger/mqtt.service';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { MessengerService } from './messenger/messenger.service';
import { ProfileService } from './profile/profile.service';
import {NewsletterService} from './newsletter/newsletterService';

@Component({

  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  public busy = false;
  public hideNavbar = false;

  private subscriptions: Subscription[] = [];
  private messengerSubscription: Subscription;

  constructor(
    public viewContainerRef: ViewContainerRef,
    private sessionService: SessionService,
    private messengerService: MessengerService,
    private pushMessageService: MessageNotificationService,
    private router: Router,
    private mqttService: MqttService,
    activatedRoute: ActivatedRoute,
    private ccService: NgcCookieConsentService,
    private profileService: ProfileService,
    private newsletterService: NewsletterService
  ) {

    activatedRoute.data.subscribe(v => this.hideNavbar = v['hideNavBar'])
  }

  onRouting(event: any) {
    if (event instanceof NavigationStart) {
      this.busy = true;
    } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
      this.busy = false;
    }
  }

  ngOnInit() {
    let subscription: Subscription = this.router.events.subscribe(event => this.onRouting(event));
    this.subscriptions.push(subscription);
    subscription = this.sessionService.sessionState.subscribe(state => {
      if (state) {
        this.messengerSubscription = this.pushMessageService.showMessageNotifications().subscribe(x => {
        }, console.error);
        this.subscriptions.push(this.messengerSubscription);
        this.mqttService.connect();
        this.newsletterService.refreshUnreadNewsletterCount();
      } else {
        if (this.messengerSubscription && !this.messengerSubscription.closed) {
          this.messengerSubscription.unsubscribe();
        }
        this.mqttService.closeConnection();
        this.messengerService.close();
      }
    });
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      if (!subscription.closed) {
        subscription.unsubscribe();
      }
    });
  }
}
