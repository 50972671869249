import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { TurbotestStatusService } from './turbotest-status.service';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class TurbotestGuardService {

  constructor(private router: Router, private status: TurbotestStatusService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.status.hasFinishedTurboTests().pipe(
      tap(hasfinished => {
        if (hasfinished) {
          this.router.navigate(['/']);
        }
      }),
      map(hasFinished => !hasFinished));
  }
}
