import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PremiumService } from '../user/premium.service';
import { SessionService } from '../user/session.service';
import { UserType } from '../user/user-type.enum';
import { SearchService } from '../shared-search/search.service';
import { first } from 'rxjs/operators';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss'],
  animations: [
    trigger('sliderState', [
      state('two', style({
        transform: 'translateX(-50%)'
      })),
      state('one', style({
        transform: 'translateX(0)'
      })),
      transition('one => two', animate('300ms ease-in-out')),
      transition('two => one', animate('300ms ease-in-out'))
    ])
  ]
})
export class IntroComponent implements OnInit, OnDestroy {
  error: any;
  currentSlide = 0;
  isLoggedIn = false;
  loading = false;

  private subscriptions: Subscription[] = [];

  private _slides = [
    {
      id: 'student',
      title: 'Für Schüler/innen',
      text: 'BERUFSWEG unterstützt Dich, einen passenden Ausbildungsweg einzuschlagen und auf einfache Weise einen direkten Kontakt zum Unternehmen aufzunehmen!',
      icon: 'school',
      type: UserType.STUDENT,
      role: 'Schüler/innen'
    },
    {
      id: 'business',
      title: 'Für Unternehmen',
      text: 'BERUFSWEG unterstützt Sie auf dem Weg, interessierte, passende Auszubildende / Bewerber für Ihre offenen Stellen zu finden. Gleichzeitig können Sie einfach und unkompliziert mit den Schülern / Interessenten auf direktem Weg über diese App in Kontakt treten. Sie benötigen einen Account? Schreiben Sie uns einfach eine E-Mail.',
      icon: 'business',
      type: UserType.COMPANY,
      role: 'Unternehmen'
    }
  ];

  constructor(
    private router: Router,
    private sessionService: SessionService,
    private premiumService: PremiumService,
    private searchService: SearchService) {
  }

  get slides(): { id: string; title: string; text: string; icon: string; type: UserType; role: string }[] {
    return this._slides;
  }

  get role(): string {
    return this._slides[this.currentSlide].role;
  }

  isCompany(): boolean {
    return this._slides[this.currentSlide].type === UserType.COMPANY;
  }

  ngOnInit() {
    const isLoggedInSubscription = this.sessionService.isLoggedIn.pipe(first())
      .subscribe(isLoggedIn => this.isLoggedIn = isLoggedIn);

    this.subscriptions.push(isLoggedInSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      if (!subscription.closed) {
        subscription.unsubscribe();
      }
    });
  }

  next() {
    this.currentSlide = (this.currentSlide + 1) % this._slides.length;
  }

  previous() {
    this.currentSlide = (this._slides.length + this.currentSlide - 1) % this._slides.length;
  }

  stateForSlider(): string {
    if (this.currentSlide === 0) {
      return 'one'
    } else {
      return 'two';
    }
  }

  public createUser() {

    this.searchService.fillSearchableValuesCache();

    this.loading = true;
    this.premiumService.setState(false);
    this.router.navigate(['signup', 'userdata']);
  }
}
