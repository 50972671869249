<div class="container-fluid">
  <div class="row white-row" #projecttext>
    <div class="col-12 content-container col-lg-6 offset-lg-3">
        <h2>Das Projekt</h2>
        <p>
          BERUFSWEG.de soll Schüler/innen auf dem Weg in ihre berufliche Zukunft unterstützen. Alle wichtigen Schritte und
          Entscheidungen,
          vor und während der Bewerbungsphase, sind berücksichtigt. BERUFSWEG.de setzt ein mit dem Beginn der
          Berufsorientierung
          in der Schule. Das System ist übersichtlich und leicht verständlich aufgebaut. Ab der achten Klasse bis hin zum
          Ausbildungs-
          oder Studienplatz bietet das System umfangreiche Möglichkeiten der beruflichen Selbstfindung, Bewerbung und
          Kontaktaufnahme.
          BERUFSWEG.de ist eine Kommunikationslösung - bestehend aus Web-Portal, APP und ergänzenden Services - mit dem
          Anspruch,
          den Kontakt zwischen Schüler/innen und Unternehmen zielgerichtet zu vermitteln. Hauptaugenmerk liegt dabei auf
          eine
          schnelle, effektive und für alle Beteiligten niedrig-schwellige Kontaktmöglichkeit mit den Zielsetzungen
          „Wunschberuf
          bzw. Stellenbesetzung“ zu schaffen. Einen schnellen Einblick in die Idee und die Funktionsweise von BERUFSWEG.de
          verschafft der folgende Trailer.
        </p>
        <div class="product-video">
          <div style="padding:56.25% 0 0 0;position:relative;">
            <iframe src="https://player.vimeo.com/video/957087424?h=05bed10af2&title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
  </div>

<!--  <div class="grey-row row">-->
<!--    <div class="col-12 content-container col-lg-6 offset-lg-3">-->
<!--    <h2>Die App</h2>-->
<!--        <p>-->
<!--          Die APP von BERUFSWEG.de ist für Android- und iOS-Geräte verfügbar. Alle Funktionalitäten von BERUFSWEG.de sind-->
<!--          mit der APP-->
<!--          nutzbar. Über den integrierten Push-Nachrichten-Dienst entgehen dem Nutzer keine neuen Mitteilungen,-->
<!--          Interessensanfragen-->
<!--          und Termine mehr. Sobald sich in dem Profil eines Nutzers etwas ändert oder Nachrichten eintreffen, wird dies-->
<!--          per-->
<!--          Statusmeldung über das Smartphone mitgeteilt.-->
<!--        </p>-->

<!--        <div class="carousel">-->
<!--          <div class="slider" (swipeleft)="nextAppSlide()" (swiperight)="previousAppSlide()">-->
<!--            <div class="slide {{ stateForAppSlide(i) }}" *ngFor="let slide of appSlides; let i= index" [@sliderState]="stateForAppSlide(i)">-->
<!--              <div class="col-6 offset-3 slide-content">-->
<!--                <div class="text-center">-->
<!--                  <img [imageId]="slide.img" alt="app slide image"/>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--          <button mat-fab (click)="previousAppSlide()" color="primary" class="left">-->
<!--            <mat-icon>chevron_left</mat-icon>-->
<!--          </button>-->

<!--          <button mat-fab (click)="nextAppSlide()" color="primary" class="right">-->
<!--            <mat-icon>chevron_right</mat-icon>-->
<!--          </button>-->

<!--          <div class="text-center indicators">-->
<!--            <i *ngFor="let slide of appSlides; let i = index" class="material-icons indicator"-->
<!--              [ngClass]="{'active': i == currentAppSlide}">&bull;</i>-->
<!--          </div>-->
<!--        </div>-->

<!--      <div class="app-buttons">-->
<!--        <a mat-raised-button class="left" color="primary" href="https://itunes.apple.com/de/genre/ios/id36?mt=8" target="_blank">-->
<!--          <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"-->
<!--            x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve" width="24">-->
<!--            <style type="text/css">-->
<!--              .st0_ios {-->
<!--                fill: none;-->
<!--              }-->
<!--            </style>-->
<!--            <path class="st0_ios" d="M0,0h24v24H0V0z" />-->
<!--            <path d="M21.7,18.7c-0.4,0.8-0.8,1.6-1.3,2.3c-0.7,1-1.2,1.6-1.7,2c-0.7,0.6-1.4,0.9-2.1,0.9c-0.5,0-1.2-0.2-2-0.5-->
<!--            c-0.8-0.3-1.5-0.5-2.1-0.5c-0.7,0-1.4,0.2-2.2,0.5C9.5,23.8,8.9,24,8.4,24c-0.7,0-1.5-0.3-2.2-1c-0.5-0.4-1-1.1-1.7-2.1-->
<!--            c-0.7-1-1.4-2.3-1.8-3.7c-0.5-1.5-0.8-2.9-0.8-4.4c0-1.6,0.3-3,1-4.2c0.5-0.9,1.3-1.7,2.2-2.2s1.9-0.8,3-0.8c0.6,0,1.3,0.2,2.3,0.5-->
<!--            c0.9,0.4,1.5,0.5,1.8,0.5c0.2,0,0.9-0.2,2-0.6c1.1-0.4,2-0.5,2.7-0.5c2,0.2,3.5,1,4.5,2.4c-1.8,1.1-2.7,2.6-2.7,4.6-->
<!--            c0,1.5,0.6,2.8,1.7,3.8c0.5,0.5,1,0.8,1.7,1.1C22,18,21.8,18.3,21.7,18.7L21.7,18.7z M17.1,0.5c0,1.2-0.4,2.3-1.3,3.4-->
<!--            c-1.1,1.2-2.3,1.9-3.7,1.8c0-0.1,0-0.3,0-0.5c0-1.2,0.5-2.4,1.4-3.4c0.4-0.5,1-0.9,1.7-1.3C15.8,0.2,16.4,0,17,0-->
<!--            C17,0.2,17.1,0.3,17.1,0.5L17.1,0.5z" />-->
<!--          </svg>-->
<!--          App für iOS-->
<!--        </a>-->
<!--        <a mat-raised-button class="right" color="primary" href="https://play.google.com/store?hl=de" target="_blank">-->
<!--          <svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path d="M0 0h24v24H0z" fill="none" />-->
<!--            <path-->
<!--              d="M6 18c0 .55.45 1 1 1h1v3.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5V19h2v3.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5V19h1c.55 0 1-.45 1-1V8H6v10zM3.5 8C2.67 8 2 8.67 2 9.5v7c0 .83.67 1.5 1.5 1.5S5 17.33 5 16.5v-7C5 8.67 4.33 8 3.5 8zm17 0c-.83 0-1.5.67-1.5 1.5v7c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5v-7c0-.83-.67-1.5-1.5-1.5zm-4.97-5.84l1.3-1.3c.2-.2.2-.51 0-.71-.2-.2-.51-.2-.71 0l-1.48 1.48C13.85 1.23 12.95 1 12 1c-.96 0-1.86.23-2.66.63L7.85.15c-.2-.2-.51-.2-.71 0-.2.2-.2.51 0 .71l1.31 1.31C6.97 3.26 6 5.01 6 7h12c0-1.99-.97-3.75-2.47-4.84zM10 5H9V4h1v1zm5 0h-1V4h1v1z" />-->
<!--          </svg>-->
<!--          App für Android-->
<!--        </a>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

  <div class="grey-row row">
    <div class="col-12 content-container col-lg-6 offset-lg-3">
        <h2>Im Web</h2>
        <p>
          Die Webpräsenz von BERUFSWEG.de bietet ein sehr klares und strukturiertes Design für den Zugriff auf alle
          gebotenen Möglichkeiten
          des Portals. Zusätzlich zu den Möglichkeiten der mobilen APP bietet das Portal für unterstützende Lehrkräfte
          einen
          erweiterten Funktionsumfang im gesamten Prozess der Berufsorientierung. Testergebnisse, Unternehmens- und
          Berufsvorschläge
          sowie Lebenslaufdaten sind - nach der Erlaubnis durch den Schüler - einsehbar. Über das interne
          Kommunikationssystem
          kann mit einzelnen Schülern oder ganzen Gruppen kommuniziert werden.
        </p>

        <div class="start-button-container">
          <button mat-raised-button (click)="gotoCreateUserBtn()" [disabled]="loading" color="primary" class="right">
            <loading-label [loading]="loading " [idle]=" 'Los geht`s' "></loading-label>
          </button>
        </div>

      <div class="carousel">
        <div class="slider" (swipeleft)="nextWebSlide()" (swiperight)="previousWebSlide()">
          <div class="slide {{ stateForWebSlide(i) }}" *ngFor="let slide of webSlides; let i= index"
            [@sliderState]="stateForWebSlide(i)">
            <div class="col-12 col-md-8 offset-md-2 slide-content">
              <div class="text-center">
                <img [imageId]="slide.img" alt="app slide image" />
              </div>
            </div>
          </div>
        </div>

        <button mat-fab (click)="previousWebSlide()" color="primary" class="left">
          <mat-icon>chevron_left</mat-icon>
        </button>

        <button mat-fab (click)="nextWebSlide()" color="primary" class="right">
          <mat-icon>chevron_right</mat-icon>
        </button>

        <div class="text-center indicators">
          <i *ngFor="let slide of webSlides; let i = index" class="material-icons indicator"
             [ngClass]="{'active': i == currentWebSlide}">&bull;</i>
        </div>
      </div>
    </div>
  </div>

  <footer class="row">
    <div class="col-12 content-container col-lg-6 offset-lg-3">
      <div class="row">
        <div class="links col-12 col-sm-4">
          <h3 class="title">Über BERUFSWEG.de</h3>
          <div class="link"><a (click)="projecttext.scrollIntoView({behavior: 'smooth'})">Das Projekt</a></div>
          <div class="link"><a href="mailto:kontakt@berufsweg.de?subject=Kontaktanfrage">Kontakt</a></div>
          <div class="link"><a href="http://www.up-consulting.de/">UP CONSULTING GmbH</a></div>
        </div>


<!--      <div class="links col-12 col-sm-3">-->
<!--        <h3 class="title">Web &amp; App</h3>-->
<!--        <div class="link"><a href="https://play.google.com/store/apps?hl=de">Android</a></div>-->
<!--        <div class="link"><a href="https://itunes.apple.com/de/genre/ios/id36?mt=8">Apple</a></div>-->
<!--      </div>-->

        <div class="links col-12 col-sm-4">
          <h3 class="title">Produkte</h3>
          <div class="link"><a href="{{ staticHtmlUrl }}firmen?backBtn=true">Firmen</a></div>
          <div class="link"><a href="{{ staticHtmlUrl }}schueler?backBtn=true">Schüler/innen</a></div>
          <div class="link"><a href="{{ staticHtmlUrl }}schule_berater?backBtn=true">Schule &amp; Berater/innen</a></div>
        </div>

        <div class="links col-12 col-sm-4">
          <h3 class="title">Über BERUFSWEG.de</h3>
          <div class="link"><a href="{{ staticHtmlUrl }}datenschutz?backBtn=true">Datenschutz</a></div>
          <div class="link"><a href="{{ staticHtmlUrl }}agbs?backBtn=true">Nutzungsbedingungen</a></div>
          <div class="link"><a href="{{ staticHtmlUrl }}impressum?backBtn=true">Impressum</a></div>
          <div class="link"><a href="{{ baseUrl }}berater">Login Lehrkräfte / Berater/innen</a></div>
          <div class="link"><a href="{{ baseUrl }}admin">Login Admin</a></div>
        </div>

        <div class="copyright row">
          <p class="text-center col-12">
          © UP CONSULTING GmbH
          </p>
        </div>
      </div>
    </div>
  </footer>
</div>
